const en = {
  homePage: {
    title: "5000 words to learn",
    subtitle: "pronunciation & spelling",
    description:
      "This app is a pronunciation and spelling trainer that you can download for free for your mobile.",
    mobileDescription: "You can also try our web demo on desktop screens.",
    credentials: "Made by thetime.pl",
    appTitle: "wrdz.app",
    slogan: "english is crazy",
    demoDetails: "check out why",
  },
  menu: {
    title: "top 5000",
    subtitle: "words to learn",
    description:
      "This a very limited demo version of the app. To get the full experience get the mobile app!",
    noVideoSupport: "Sorry, your browser does not support embedded videos.",
  },
  slideContent: {
    ukVersion: "Nelly, UK",
    usVersion: "Jake, US",
    noResults: "There are no words that meet your requirements",
    howToRead: "How do you read it?",
  },
  summary: {
    title: "Remember all?",
    moreWords: "get another 5 words",
    allWords: "see all 5 words",
    downloadApp: "Need translations?\n Download the app",
  },
  maintenance: {
    pageNotFound: "Page not found",
  },
  buttons: {
    accept: "Accept",
    decline: "Decline",
    start: "start",
    comingSoon: "Coming soon",
    wordFirst: "word first",
    transcriptionFirst: "transcription first",
    first: "first",
    englishWord: "English Word",
    ipaTranscription: "IPA Transcription",
    translation: "translation",
    next: "next",
    chinese: "Chinese",
    english: "English",
    french: "French",
    german: "German",
    italian: "Italian",
    polish: "Polish",
    portugese: "Portugese",
    russian: "Russian",
    spanish: "Spanish",
  },
  cookies: {
    title: "Cookies policy",
    description:
      "Our website uses cookies to analyze how that site is used and to ensure your experience is consistent between the visits.",
    link: "Cookies Policy",
  },
};

export default en;
