import React, { Component } from "react";
import {
  ApplicationMode,
  changePronunciationLanguage,
  IWordItem,
  PronunciationLanguage,
  TranslationLanguage,
} from "../../../../store/reducers/sagaSlice";
import Translation from "../../../Translation";
import {
  withRouterWrapper,
  WithRouterWrapperProps,
} from "../../../hoc/withRouterWrapper";
import SlideControls from "../PronunciationSlide/SlideControls";

interface IWordSlideProps extends WithRouterWrapperProps {
  readonly selectedWords: IWordItem[] | null;
  readonly isShareMode: boolean;
  readonly selectedWordIndex: number;
  readonly mode: ApplicationMode;
  readonly localMode: ApplicationMode;
  readonly pronunciationLanguage: PronunciationLanguage;
  readonly changePronunciationLanguage: typeof changePronunciationLanguage;
  readonly changeLocalApplicationMode: () => void;
  readonly changeSelectedWordIndex: (index: number) => void;
  readonly translationLanguage: TranslationLanguage;
}

interface IWordSlideState {
  selectedWordIndex: number;
}

class WordSlide extends Component<IWordSlideProps, IWordSlideState> {
  private readonly audioRef: any;
  private readonly navigationBtnsRef: any;
  private readonly pronunciationVersionBtnRef: any;

  constructor(props: IWordSlideProps) {
    super(props);

    this.state = {
      selectedWordIndex: this.props.selectedWordIndex
        ? this.props.selectedWordIndex
        : 0,
    };
    this.audioRef = React.createRef();
    this.navigationBtnsRef = React.createRef();
    this.pronunciationVersionBtnRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, true);
    document.addEventListener("click", this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, true);
    document.removeEventListener("click", this.handleClick, true);
    if (this.audioRef && this.audioRef.current) {
      this.audioRef.current.pause();
    }
  }

  render() {
    const words = this.props.selectedWords,
      index = this.state.selectedWordIndex,
      hasWords = words !== null && words !== undefined && words.length,
      isWordMode = this.props.mode === ApplicationMode.WORD,
      translationLanguage = this.props.translationLanguage;

    return (
      <div className="slide">
        {this.props.isShareMode && index === 0 ? (
          <p className="share-subtitle">
            <Translation text="slideContent.howToRead" />
          </p>
        ) : null}
        {hasWords ? (
          <>
            {!this.props.isShareMode ? (
              <>
                <SlideControls
                  pronunciationLanguage={this.props.pronunciationLanguage}
                  selectedWords={this.props.selectedWords}
                  selectedWordIndex={this.state.selectedWordIndex}
                  audioRef={this.audioRef}
                  changeWord={this.changeWord}
                  navigationBtnsRef={this.navigationBtnsRef}
                  pronunciationVersionBtnRef={this.pronunciationVersionBtnRef}
                  changePronunciationLanguage={
                    this.props.changePronunciationLanguage
                  }
                />
              </>
            ) : null}

            <p
              className={`word ${!isWordMode ? "pronunciation" : ""}`}
              key={index}
            >
              {/* {isWordMode
                ? words[index]?.english
                : this.props.pronunciationLanguage ===
                  PronunciationLanguage.UK_EN
                ? words[index].br
                : words[index]?.am} */}
              {this.props.mode === ApplicationMode.WORD ? (
                words[index]?.english
              ) : this.props.mode === ApplicationMode.TRANSLATION ? (
                <>
                  {translationLanguage === TranslationLanguage.CHINESE
                    ? words[index]?.chinese
                    : translationLanguage === TranslationLanguage.ENGLISH
                    ? words[index]?.english
                    : translationLanguage === TranslationLanguage.FRENCH
                    ? words[index]?.french
                    : translationLanguage === TranslationLanguage.GERMAN
                    ? words[index]?.german
                    : translationLanguage === TranslationLanguage.ITALIAN
                    ? words[index]?.italian
                    : translationLanguage === TranslationLanguage.POLISH
                    ? words[index]?.polish
                    : translationLanguage === TranslationLanguage.PORTUGESE
                    ? words[index]?.portugese
                    : translationLanguage === TranslationLanguage.RUSSIAN
                    ? words[index]?.russian
                    : translationLanguage === TranslationLanguage.SPANISH
                    ? words[index]?.spanish
                    : words[index]?.polish}
                </>
              ) : this.props.pronunciationLanguage ===
                PronunciationLanguage.UK_EN ? (
                words[index].br
              ) : (
                words[index]?.am
              )}
            </p>
          </>
        ) : (
          <p className="no-results">
            <Translation text="slideContent.noResults" />
          </p>
        )}
      </div>
    );
  }

  private handleKeyDown = (e: any) => {
    const index = this.state.selectedWordIndex,
      words = this.props.selectedWords;

    if (words === null) {
      return;
    }

    if (e.keyCode === 39 || e.keyCode === 32) {
      this.handleClick(e);
    }

    if (e.keyCode === 37 && index > 0) {
      // this.handleClick(e);
      this.setState(
        (prevState) => ({
          selectedWordIndex: prevState.selectedWordIndex - 1,
        }),
        () => {
          this.changeLocalApplicationMode(this.state.selectedWordIndex);
        }
      );
    }

    if (e.keyCode === 27) {
      this.props.router.navigate("/");
    }
  };

  private handleClick = (e: any) => {
    if (
      (this.navigationBtnsRef &&
        this.navigationBtnsRef.current &&
        this.navigationBtnsRef.current.contains(e.target)) ||
      (this.pronunciationVersionBtnRef &&
        this.pronunciationVersionBtnRef.current &&
        this.pronunciationVersionBtnRef.current.contains(e.target))
    ) {
      return;
    } else {
      this.props.changeLocalApplicationMode();
      this.props.changeSelectedWordIndex(this.state.selectedWordIndex);
    }
  };

  private changeWord = (index: number) => {
    const words = this.props.selectedWords;

    if (words && index <= words.length - 1) {
      this.setState({ selectedWordIndex: index });
    }
  };

  private changeLocalApplicationMode = (index: number) => {
    this.props.changeLocalApplicationMode();
    this.props.changeSelectedWordIndex(index);
  };
}

export default withRouterWrapper(WordSlide);
