import React, {Component} from 'react';
import Translation from "../Translation";

class NotFoundPage extends Component {
    render() {
        return (
            <div className="page-not-found">
                <h2 className="title">404</h2>
                <p className="description">
                    <Translation text="maintenance.pageNotFound" />
                </p>
            </div>
        );
    }
}

export default NotFoundPage;
