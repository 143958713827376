import React from "react";
import YouTubeVideo from "./YouTubeVideo";
import { connect } from "react-redux";
import { RootState } from "../../store/reducers";
import { applicationSettingsSelector } from "../../store/selectors/sagaSelectors";
import { ISettings } from "../../store/reducers/sagaSlice";

interface IVideoProps {
  readonly applicationSettings: ISettings | null;
}

interface IVideoState {
  isVideoInit: boolean;

  videoUrl: string | null;
}

class VideoComponent extends React.Component<IVideoProps, IVideoState> {
  constructor(props: IVideoProps) {
    super(props);

    // this.state = {
    //   isVideoInit: false,
    //   videoUrl: null,
    // };
    this.state = {
      isVideoInit: false,
      videoUrl:
        "https://www.youtube.com/embed/iNHVB7OVMBg?autoplay=1&enablejsapi=1&origin=https%3A%2F%2Fwrdz.app&widgetid=1",
    };
  }

  componentDidMount() {
    if (this.props.applicationSettings) {
      this.setState({
        videoUrl: this.props.applicationSettings.introduction_video_yt_link,
      });
    }
  }

  render() {
    return (
      <div className="video-host">
        <div
          className={`video-container ${
            this.state.isVideoInit ? "hidden" : ""
          } ${!this.state.videoUrl ? "disabled" : ""}`}
          onClick={() => {
            this.playVideo();
          }}
        />
        {this.state.isVideoInit ? (
          <YouTubeVideo videoId={this.getVideoId(this.state.videoUrl)} />
        ) : null}
      </div>
    );
  }

  private playVideo = () => {
    this.setState({ isVideoInit: !this.state.isVideoInit });
  };

  private getVideoId = (url: string | null) => {
    if (
      url &&
      (url.includes("youtu.be") ||
        url.includes("youtube.com/watch") ||
        url.includes("youtube.com/embed") ||
        url.includes("youtube.com/v/"))
    ) {
      const videoId = url.split(
        /(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
      )[1];
      return videoId;
    } else {
      return null;
    }
  };
}

export default connect(
  (state: RootState) => ({
    applicationSettings: applicationSettingsSelector(state),
  }),

  {}
)(VideoComponent);
