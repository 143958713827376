import { combineEpics, Epic, ofType, StateObservable } from "redux-observable";
import { forkJoin, of, from } from "rxjs";
import { catchError, switchMap, mergeMap } from "rxjs/operators";
import {
  changePreviouslySelectedIds,
  changeSelectedWords,
  fetchApplicationSettings,
  fetchPredefinedWords,
  fetchWords,
  IFetchPredefinedWords,
  setApplicationSettings,
} from "../reducers/sagaSlice";
import {
  applicationLevelSelector,
  previouslySelectedIdsSelector,
} from "../selectors/sagaSelectors";
import axios from "axios";
import { API_ENDPOINT } from "../../components/PageHost/types";
import { PayloadAction } from "@reduxjs/toolkit";
import store from "../index";

import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";

export enum ErrorCodes {
  NOT_FOUND = "ERR_BAD_REQUEST",
}

const db = getFirestore();
const wordsCollection = collection(db, "words");
let levelTotalItems = 999;

const fetchWordsEpic: Epic = (action$, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(fetchWords.type),
    switchMap(async () => {
      const level = applicationLevelSelector(state$.value);
      const previouslySelectedIds = previouslySelectedIdsSelector(state$.value);
      console.log("Old levelTotalItems", levelTotalItems);
      levelTotalItems = await getLevelLength(level);
      console.log("fetchWordsEpic Total Lenght", levelTotalItems);

      // const host = window.location.hostname;

      let selectedNumbers: string[] = [];

      // if (
      //   host !== process.env.REACT_APP_LOCALHOST_URL &&
      //   host !== process.env.REACT_APP_STAGING_URL
      // ) {
      //   console.log("live server", previouslySelectedIds);
      //   selectedNumbers = await getRandomNumbers(level, previouslySelectedIds);
      // } else {
      //   console.log("test server");
      //   selectedNumbers = await getRandomTest(level);
      // }
      selectedNumbers = await getRandomNumbers(level, previouslySelectedIds);
      console.log("selectedNumbers", selectedNumbers);

      const requestArrays = await Promise.all(
        selectedNumbers.map(async (id: string) => {
          try {
            const data = await fetchWordsWithRetries(
              id,
              selectedNumbers,
              previouslySelectedIds,
              level
            );
            return data;
          } catch (error) {
            console.log("Error:", error);
            return null; // or handle the error as needed
          }
        })
      );

      const words = requestArrays.every((element: any) => element !== null)
        ? requestArrays
        : [];

      return [
        changePreviouslySelectedIds(selectedNumbers),
        changeSelectedWords(words),
      ];
    }),
    mergeMap((actions) => from(actions)),
    catchError((error: any) => {
      console.log("Error:", error);
      return of();
    })
  );

const fetchPredefinedWordsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(fetchPredefinedWords.type),
    switchMap(async (action: PayloadAction<IFetchPredefinedWords>) => {
      const wordSlug = action.payload.wordUuid;
      const q = query(wordsCollection, where("english", "==", `${wordSlug}`));
      const res = await getDocs(q);
      const word = res.docs.map((e) => e.data())[0];
      if (word && word.id) {
        return { ...word, uid: word.id };
      } else {
        return null;
      }
    }),
    switchMap(async (resp: any) => {
      if (!resp) {
        return of(changeSelectedWords([]));
      }
      const wordId = resp.uid;
      const selectedLevel = parseInt(resp.level);
      const previouslySelectedIds: string[] = [];
      levelTotalItems = await getLevelLength(selectedLevel);
      console.log("fetchPredefinedWordsEpic Level Total", levelTotalItems);
      // const host = window.location.hostname;

      let selectedNumbers: string[] = [];

      // if (
      //   host !== process.env.REACT_APP_LOCALHOST_URL &&
      //   host !== process.env.REACT_APP_STAGING_URL
      // ) {
      //   selectedNumbers = await getRandomNumbers(
      //     selectedLevel,
      //     previouslySelectedIds,
      //     true,
      //     wordId
      //   );
      // } else {
      //   selectedNumbers = await getRandomTest(selectedLevel, true, wordId);
      // }
      selectedNumbers = await getRandomNumbers(
        selectedLevel,
        previouslySelectedIds,
        true,
        wordId
      );
      selectedNumbers.unshift(wordId);

      const requestArrays = await Promise.all(
        selectedNumbers.map(async (id: string) => {
          try {
            const data = await fetchWordsWithRetries(
              id,
              selectedNumbers,
              previouslySelectedIds,
              selectedLevel
            );
            return data;
          } catch (error) {
            console.log("Error:", error);
            return null;
          }
        })
      );

      const words = requestArrays.every((element: any) => element !== null)
        ? requestArrays
        : [];

      return [
        changePreviouslySelectedIds(selectedNumbers),
        changeSelectedWords(words),
      ];
    }),
    mergeMap((actions) => from(actions)),
    catchError((error: any) => {
      console.log("Error:", error);
      return of();
    })
  );

const fetchApplicationSettingsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(fetchApplicationSettings.type),
    switchMap(async () => {
      setApplicationSettings(null);
      const apiUrl = process.env.REACT_APP_ENDPOINT_URL,
        url = apiUrl + "/getSettings";
      try {
        const response = await axios.get(url);
        const parsedData =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        return setApplicationSettings(parsedData);
      } catch {
        return setApplicationSettings(null);
      }
    }),
    catchError(() => {
      return of(setApplicationSettings(null));
    })
  );

async function getRandomNumbers(
  level: number,
  previouslySelectedIds: string[],
  isShareMode?: boolean,
  uuid?: string
): Promise<string[]> {
  const arr = [];
  const arrLength = isShareMode ? 4 : 5;

  while (arr.length < arrLength) {
    let randomQuestionNumber = await getRandomQuestionNumber(level);
    if (
      arr.indexOf(randomQuestionNumber) === -1 &&
      previouslySelectedIds.indexOf(randomQuestionNumber) === -1
    ) {
      if (isShareMode && uuid && arr.indexOf(uuid) === -1) {
        arr.push(randomQuestionNumber);
      } else {
        arr.push(randomQuestionNumber);
      }
    }
  }

  return arr;
}

async function getRandomNumber(
  level: number,
  generatedNumbers: string[],
  previouslySelectedIds: string[]
): Promise<string | null> {
  let randomNumber = await getRandomQuestionNumber(level);
  const randomString = randomNumber.toString().padStart(4, "0");

  if (
    generatedNumbers.indexOf(randomString) === -1 &&
    previouslySelectedIds.indexOf(randomString) === -1
  ) {
    return randomString;
  }

  return null;
}

// async function getRandomTest(
//   level: number,
//   isShareMode?: boolean,
//   uuid?: string
// ): Promise<string[]> {
//   let optionsArr: string[] = [];
//   // [
//   //   "0245",
//   //   "0768",
//   //   "0932",
//   //   "0056",
//   //   "0412",
//   //   "0956",
//   //   "0201",
//   //   "0634",
//   //   "0854",
//   //   "0098",
//   //   "0521",
//   //   "0167",
//   //   "0843",
//   //   "0276",
//   //   "0389",
//   //   "0601",
//   //   "0145",
//   //   "0490",
//   //   "0923",
//   //   "0076",
//   //   "0810",
//   //   "0267",
//   //   "0932",
//   //   "0056",
//   //   "0489",
//   //   "0701",
//   //   "0245",
//   //   "0378",
//   //   "0689",
//   //   "0001",
//   //   "0476",
//   //   "0889",
//   // ];

//   // if (level === 5) {
//   //   optionsArr = [
//   //     "0001",
//   //     "0004",
//   //     "0006",
//   //     "0012",
//   //     "0012",
//   //     "0013",
//   //     "0014",
//   //     "0015",
//   //     "0016",
//   //     "0017",
//   //     "0018",
//   //   ];
//   // }

//   const arr = Array.from({ length: levelTotalItems + 1 }, (_, i) =>
//     (i + 1).toString().padStart(4, "0")
//   );
//   optionsArr = arr;

//   if (isShareMode && uuid) {
//     optionsArr = optionsArr.filter((wordId) => wordId !== uuid);
//   }

//   let wordLevelPrefix: string;
//   switch (level) {
//     case 1:
//       wordLevelPrefix = "A";
//       break;
//     case 2:
//       wordLevelPrefix = "B";
//       break;
//     case 3:
//       wordLevelPrefix = "C";
//       break;
//     case 4:
//       wordLevelPrefix = "D";
//       break;
//     case 5:
//       wordLevelPrefix = "E";
//       break;
//     default:
//       break;
//   }

//   const arrayLength = isShareMode ? 4 : 5,
//     array = optionsArr
//       .sort(() => Math.random() - Math.random())
//       .slice(0, arrayLength);
//   return array.map((word) => `${wordLevelPrefix}${word}`);
// }

// get length of level from database
const getLevelLength = async (level: number) => {
  const q = query(wordsCollection, where("level", "==", `${level}`));
  return await getDocs(q).then((res) => {
    return res.docs.length;
  });
};

async function getRandomQuestionNumber(level: number): Promise<string> {
  const min = 1;
  let max = 999;
  max = levelTotalItems + 1;

  let wordLevelPrefix = "";

  switch (level) {
    case 1:
      wordLevelPrefix = "A";
      break;
    case 2:
      wordLevelPrefix = "B";
      break;
    case 3:
      wordLevelPrefix = "C";
      break;
    case 4:
      wordLevelPrefix = "D";
      break;
    case 5:
      // max = 100;
      wordLevelPrefix = "E";
      break;
    default:
      break;
  }

  const randomInt = Math.floor(Math.random() * (max - min + 1) + min);
  return `${wordLevelPrefix}${randomInt.toString().padStart(4, "0")}`;
}

async function fetchWordsWithRetries(
  id: string,
  currentlySelectedIds: string[],
  previouslySelectedIds: string[],
  level: number
): Promise<any> {
  try {
    // new
    const q = query(
      wordsCollection,
      where("id", "==", `${id}`),
      orderBy("id"),
      limit(5)
    );
    const res = await getDocs(q);
    return res.docs.map((e) => e.data())[0];
    // end new
    // const url = API_ENDPOINT + id;
    // response = await axios.get(url);

    // if (response.data.level !== level) {
    //   let newNumber = getRandomNumber(level, currentlySelectedIds, [
    //     ...previouslySelectedIds,
    //     id,
    //   ]);
    //   if (newNumber) {
    //     return fetchWordsWithRetries(
    //       newNumber,
    //       currentlySelectedIds,
    //       [...previouslySelectedIds, id],
    //       level
    //     );
    //   }
    // }

    // return response.data;
  } catch (error: any) {
    if (error.code === ErrorCodes.NOT_FOUND) {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const arr = [id];
      store.dispatch(changePreviouslySelectedIds(arr));
      let newNumber = await getRandomNumber(level, currentlySelectedIds, [
        ...previouslySelectedIds,
        id,
      ]);

      if (newNumber) {
        let index = currentlySelectedIds.indexOf(id);

        if (index !== -1) {
          currentlySelectedIds[index] = newNumber;
        }

        return await fetchWordsWithRetries(
          newNumber,
          currentlySelectedIds,
          [...previouslySelectedIds, id],
          level
        );
      }
    }
  }
}

const sagaEpic = combineEpics(
  fetchWordsEpic,
  fetchPredefinedWordsEpic,
  fetchApplicationSettingsEpic
);

export default sagaEpic;
