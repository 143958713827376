import { useState, useEffect } from "react";
import {
  ApplicationMode,
  PronunciationLanguage,
  changePronunciationLanguage,
  fetchPredefinedWords,
  fetchWords,
} from "../../../store/reducers/sagaSlice";
import WordSlide from "./WordSlide";
import PronunciationSlide from "./PronunciationSlide";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import {
  applicationModeSelector,
  isLoadingSelector,
  pronunciationLanguageSelector,
  selectedWordsSelector,
  translationLanguageSelector,
} from "../../../store/selectors/sagaSelectors";
import AdSense from "react-adsense";
import { useParams } from "react-router-dom";

export default function Index() {
  const [isShareMode, setIsShareMode] = useState(false);
  const [selectedWordIndex, setSelectedWordIndex] = useState(0);
  const [localApplicationMode, setLocalApplicationMode] =
    useState<ApplicationMode>(ApplicationMode.WORD);

  const siteKey = process.env.REACT_APP_ADSENSE_TOKEN;
  const adSenseSlot = process.env.REACT_APP_ADSENSE_SLOT;
  const dispatch = useDispatch();

  const mode = useSelector((state: RootState) =>
    applicationModeSelector(state)
  );
  const pronunciationLanguage = useSelector((state: RootState) =>
    pronunciationLanguageSelector(state)
  );
  const translationLanguage = useSelector((state: RootState) =>
    translationLanguageSelector(state)
  );
  const isLoading = useSelector((state: RootState) => isLoadingSelector(state));
  const selectedWords = useSelector((state: RootState) =>
    selectedWordsSelector(state)
  );

  const changeSelectedWordIndex = (index: number) => {
    setSelectedWordIndex(index);
  };

  const changeApplicationMode = () => {
    setLocalApplicationMode(
      localApplicationMode === ApplicationMode.WORD
        ? ApplicationMode.TRANSCRIPTION
        : ApplicationMode.WORD

      // localApplicationMode === ApplicationMode.WORD
      //   ? ApplicationMode.TRANSCRIPTION
      //   : localApplicationMode === ApplicationMode.TRANSCRIPTION
      //   ? ApplicationMode.TRANSLATION
      //   : ApplicationMode.WORD
    );
  };

  const handleChangePronunciationLanguage = (value: PronunciationLanguage) => {
    dispatch(changePronunciationLanguage(value));
  };

  const routeParams = useParams();

  useEffect(() => {
    if (routeParams.slug) {
      const slug = routeParams.slug;
      if (slug) {
        setLocalApplicationMode(ApplicationMode.WORD);
        setIsShareMode(true);
        dispatch(fetchPredefinedWords(slug));
      }
    } else {
      dispatch(fetchWords());
    }
  }, []);

  return (
    <div
      className="slide-content"
      key={`${selectedWordIndex}_${localApplicationMode}`}
    >
      <div className="slide-content-background">
        <div className="slide-content-circle"></div>

        {isLoading ? null : localApplicationMode === ApplicationMode.WORD ? (
          <WordSlide
            selectedWords={selectedWords}
            selectedWordIndex={selectedWordIndex}
            changeSelectedWordIndex={changeSelectedWordIndex}
            pronunciationLanguage={pronunciationLanguage}
            isShareMode={isShareMode}
            mode={mode}
            localMode={localApplicationMode}
            changePronunciationLanguage={handleChangePronunciationLanguage}
            changeLocalApplicationMode={changeApplicationMode}
            translationLanguage={translationLanguage}
          />
        ) : (
          <PronunciationSlide
            selectedWords={selectedWords}
            pronunciationLanguage={pronunciationLanguage}
            changeLocalApplicationMode={changeApplicationMode}
            selectedWordIndex={selectedWordIndex}
            mode={mode}
            localMode={localApplicationMode}
            changeSelectedWordIndex={changeSelectedWordIndex}
            // changePronunciationLanguage={changePronunciationLanguage}
            changePronunciationLanguage={handleChangePronunciationLanguage}
            translationLanguage={translationLanguage}
          />
        )}

        <AdSense.Google
          client={siteKey}
          slot={adSenseSlot}
          style={{
            display: "block",
            position: "absolute",
            boxSizing: "border-box",
            right: "10px",
            left: "10px",
            minWidth: "calc(100% - 20px)",
            bottom: "10px",
          }}
          // layout='in-article'
          // format='fluid'
          format=""
        />
      </div>
    </div>
  );
}
