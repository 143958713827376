import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LanguageLocale } from "../../constants/locales";
import {} from "../../store/reducers/sagaSlice";

export enum ApplicationMode {
  WORD = "word",
  TRANSCRIPTION = "transcription",
  TRANSLATION = "translation",
}

export enum TranslationLanguage {
  CHINESE = "chinese",
  FRENCH = "french",
  GERMAN = "german",
  ITALIAN = "italian",
  POLISH = "polish",
  PORTUGESE = "portugese",
  RUSSIAN = "russian",
  SPANISH = "spanish",
  ENGLISH = "english",
}

export enum PronunciationLanguage {
  UK_EN = "uk",
  US_EN = "us",
}

export interface IWordItem {
  id: number; // string;
  english: string;
  pos: string;
  level: number;
  br: string;
  am: string;
  mp3Br: string;
  mp3Am: string;
  chinese: string;
  french: string;
  german: string;
  italian: string;
  japanese: string;
  polish: string;
  russian: string;
  shareCount: number;
  portugese: string;
  slug: string | null;
  soundCount: number;
  spanish: string;
  starCount: number;
  times: number[];
  uid: string;
  warning: number;
}

export interface ISettings {
  introduction_video_yt_link: string | null;
  levelDescriptions: { [key: string]: any } | null;
  recordingDuration: number;
  starChance: number;
  levels: { [key: string]: number };
}

export interface ISagaState {
  activeLanguage: LanguageLocale;
  isCookieAccepted: boolean | null;
  level: number;
  mode: ApplicationMode;
  translationLanguage: TranslationLanguage;
  pronunciationLanguage: PronunciationLanguage;
  previouslySelectedIds: string[];
  selectedWords: IWordItem[] | null;
  applicationSettings: ISettings | null;
  isLoading: boolean;
  isFilterWindowActive: boolean;
  soundsFilter: string[];
}

export interface IChangeLanguage {
  activeLanguage: LanguageLocale;
}

export interface IChangeTranslationLanguage {
  translationLanguage: TranslationLanguage;
}

export interface IChangeLevel {
  level: number;
}

export interface IChangeMode {
  mode: ApplicationMode;
}

export interface IChangePronunciationLanguage {
  pronunciationLanguage: PronunciationLanguage;
}

export interface IToggleIsFilterWindowActive {
  isActive: boolean;
}

export interface IChangeSelectedWords {
  selectedWords: IWordItem[];
}

export interface IFetchWords {
  level: number;
}

export interface IChangeCookies {
  isCookieAccepted: boolean;
}

export interface IChangePreviouslySelectedIds {
  currentlySelectedIds: string[];
}
export interface ISoundsFilter {
  soundsFilter: string[];
}

export interface IFetchPredefinedWords {
  wordUuid: string;
}

export interface ISetApplicationSettings {
  settings: ISettings | null;
}

const initialState: ISagaState = {
  activeLanguage: LanguageLocale.EN,
  isCookieAccepted: null,
  level: 1,
  mode: ApplicationMode.WORD,
  translationLanguage: TranslationLanguage.POLISH,
  pronunciationLanguage: PronunciationLanguage.UK_EN,
  previouslySelectedIds: [],
  selectedWords: null,
  applicationSettings: null,
  isLoading: false,
  isFilterWindowActive: false,
  soundsFilter: [],
};

const sagaSlice = createSlice({
  name: "saga",
  initialState: initialState,
  reducers: {
    changeActiveLanguage: {
      reducer: (state: ISagaState, action: PayloadAction<IChangeLanguage>) => {
        return {
          ...state,
          activeLanguage: action.payload.activeLanguage,
        };
      },
      prepare(activeLanguage: LanguageLocale) {
        return {
          payload: { activeLanguage: activeLanguage },
        };
      },
    },
    changeTranslationLanguage: {
      reducer: (
        state: ISagaState,
        action: PayloadAction<IChangeTranslationLanguage>
      ) => {
        return {
          ...state,
          translationLanguage: action.payload.translationLanguage,
        };
      },
      prepare(translationLanguage: TranslationLanguage) {
        return {
          payload: { translationLanguage: translationLanguage },
        };
      },
    },
    changeApplicationLevel: {
      reducer: (state: ISagaState, action: PayloadAction<IChangeLevel>) => {
        return {
          ...state,
          level: action.payload.level,
        };
      },
      prepare(level: number) {
        return {
          payload: { level: level },
        };
      },
    },
    changeApplicationMode: {
      reducer: (state: ISagaState, action: PayloadAction<IChangeMode>) => {
        return {
          ...state,
          mode: action.payload.mode,
        };
      },
      prepare(mode: ApplicationMode) {
        return {
          payload: { mode: mode },
        };
      },
    },
    changePronunciationLanguage: {
      reducer: (
        state: ISagaState,
        action: PayloadAction<IChangePronunciationLanguage>
      ) => {
        return {
          ...state,
          pronunciationLanguage: action.payload.pronunciationLanguage,
        };
      },
      prepare(pronunciationLanguage: PronunciationLanguage) {
        return {
          payload: { pronunciationLanguage: pronunciationLanguage },
        };
      },
    },
    toggleIsFilterWindowActive: (
      state: ISagaState,
      action: PayloadAction<IToggleIsFilterWindowActive>
    ) => {
      return {
        ...state,
        isFilterWindowActive: action.payload.isActive,
      };
    },
    fetchWords: (state: ISagaState) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    fetchApplicationSettings: (state: ISagaState) => {
      return {
        ...state,
      };
    },
    setApplicationSettings: {
      reducer: (
        state: ISagaState,
        action: PayloadAction<ISetApplicationSettings>
      ) => {
        return {
          ...state,
          applicationSettings: action.payload.settings,
        };
      },
      prepare(settings: ISettings | null) {
        return {
          payload: { settings: settings },
        };
      },
    },
    fetchPredefinedWords: {
      reducer: (state: ISagaState) => {
        return {
          ...state,
          isLoading: true,
        };
      },
      prepare(wordUuid: string) {
        return {
          payload: { wordUuid: wordUuid },
        };
      },
    },
    changeSelectedWords: {
      reducer: (
        state: ISagaState,
        action: PayloadAction<IChangeSelectedWords>
      ) => {
        return {
          ...state,
          selectedWords: action.payload.selectedWords,
          isLoading: false,
        };
      },
      prepare(selectedWords: IWordItem[]) {
        return {
          payload: { selectedWords: selectedWords },
        };
      },
    },
    changeSoundsFilter: (
      state: ISagaState,
      action: PayloadAction<ISoundsFilter>
    ) => {
      const soundsFilter = [...action.payload.soundsFilter];
      return {
        ...state,
        soundsFilter,
      };
    },
    changePreviouslySelectedIds: {
      reducer: (
        state: ISagaState,
        action: PayloadAction<IChangePreviouslySelectedIds>
      ) => {
        const previousIds = [...state.previouslySelectedIds];
        return {
          ...state,
          previouslySelectedIds: [
            ...previousIds,
            ...action.payload.currentlySelectedIds,
          ],
        };
      },
      prepare(currentlySelectedIds: string[]) {
        return {
          payload: { currentlySelectedIds: currentlySelectedIds },
        };
      },
    },
    changeCookies: {
      reducer: (state: ISagaState, action: PayloadAction<IChangeCookies>) => {
        return {
          ...state,
          isCookieAccepted: action.payload.isCookieAccepted,
        };
      },
      prepare(isCookieAccepted: boolean) {
        return {
          payload: { isCookieAccepted: isCookieAccepted },
        };
      },
    },
    clearSelectedWords: (state: ISagaState) => {
      return {
        ...state,
        selectedWords: [],
        isLoading: false,
      };
    },
  },
});

export const {
  changeActiveLanguage,
  changeApplicationLevel,
  changeApplicationMode,
  changeTranslationLanguage,
  changePronunciationLanguage,
  fetchWords,
  fetchPredefinedWords,
  fetchApplicationSettings,
  setApplicationSettings,
  changeSelectedWords,
  changeCookies,
  changePreviouslySelectedIds,
  clearSelectedWords,
  toggleIsFilterWindowActive,
  changeSoundsFilter,
} = sagaSlice.actions;

export default sagaSlice.reducer;
