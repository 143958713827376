import React, { Component } from "react";
import Translation from "../../Translation";
import {
  ApplicationMode,
  TranslationLanguage,
  changeApplicationLevel,
  changeApplicationMode,
} from "../../../store/reducers/sagaSlice";
import {
  withRouterWrapper,
  WithRouterWrapperProps,
} from "../../hoc/withRouterWrapper";
import VideoComponent from "../../VideoComponent";
import FiltersComponent from "../../FiltersComponent/FiltersComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import {
  isFilterActiveSelector,
  applicationLevelSelector,
  applicationModeSelector,
} from "../../../store/selectors/sagaSelectors";
import { useNavigate } from "react-router-dom";

// import AdSense from 'react-adsense';

const levelControls = [1, 2, 3, 4, 5];

// export default withRouterWrapper(StartSlide);

export default function StartSlide({
  level,
  mode,
  isFilterWindowActive,
  changeApplicationLevel,
  changeApplicationMode,
  toggleIsFilterWindowActive,
  translationLanguage,
  changeTranslationLanguage,
}: {
  level: number | null;
  mode: ApplicationMode;
  isFilterWindowActive: boolean;
  translationLanguage: TranslationLanguage;
  changeApplicationLevel: (e: number) => void;
  changeApplicationMode: (e: ApplicationMode) => void;
  toggleIsFilterWindowActive: (e: { isActive: boolean }) => void;
  changeTranslationLanguage: (e: TranslationLanguage) => void;
}) {
  const navigate = useNavigate();

  const onFilterButtonClick = () => {
    toggleIsFilterWindowActive({ isActive: true });
  };
  const renderLevelControls = (controls: number[]) => {
    return (
      <ul className="level-controls">
        {controls.map((control: number) => {
          return (
            <li
              key={control}
              onClick={() => changeApplicationLevel(control)}
              className={`a-text --medium control ${
                control === level ? "active" : ""
              }`}
            >
              {control}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderModeControl = () => {
    const selectedMode = mode,
      // translationKey =
      //   selectedMode === ApplicationMode.WORD
      //     ? 'buttons.wordFirst'
      //     : 'buttons.transcriptionFirst';

      translationKey =
        selectedMode === ApplicationMode.WORD
          ? "buttons.englishWord"
          : selectedMode === ApplicationMode.TRANSCRIPTION
          ? "buttons.ipaTranscription"
          : "buttons.translation";
    return (
      <button
        className="btn btn-mode a-text --textSpaced"
        style={{
          marginBottom: 0,
        }}
        onClick={() =>
          changeApplicationMode(
            selectedMode === ApplicationMode.WORD
              ? ApplicationMode.TRANSCRIPTION
              : selectedMode === ApplicationMode.TRANSCRIPTION
              ? ApplicationMode.TRANSLATION
              : ApplicationMode.WORD
          )
        }
      >
        <Translation text={"buttons.first"} />
        {": "}
        <Translation text={translationKey} />
      </button>
    );
  };

  const renderLanguageTranslationControl = () => {
    const selectedTranslationLanguage = translationLanguage,
      languageKey =
        selectedTranslationLanguage === TranslationLanguage.CHINESE
          ? "buttons.chinese"
          : selectedTranslationLanguage === TranslationLanguage.ENGLISH
          ? "buttons.english"
          : selectedTranslationLanguage === TranslationLanguage.FRENCH
          ? "buttons.french"
          : selectedTranslationLanguage === TranslationLanguage.GERMAN
          ? "buttons.german"
          : selectedTranslationLanguage === TranslationLanguage.ITALIAN
          ? "buttons.italian"
          : selectedTranslationLanguage === TranslationLanguage.POLISH
          ? "buttons.polish"
          : selectedTranslationLanguage === TranslationLanguage.PORTUGESE
          ? "buttons.portugese"
          : selectedTranslationLanguage === TranslationLanguage.RUSSIAN
          ? "buttons.russian"
          : selectedTranslationLanguage === TranslationLanguage.SPANISH
          ? "buttons.spanish"
          : "buttons.polish";
    return (
      <button
        className="btn btn-mode a-text --textSpaced"
        style={{
          marginTop: ".75rem",
          marginBottom: 0,
        }}
        onClick={() =>
          changeTranslationLanguage(
            selectedTranslationLanguage === TranslationLanguage.CHINESE
              ? TranslationLanguage.ENGLISH
              : selectedTranslationLanguage === TranslationLanguage.ENGLISH
              ? TranslationLanguage.FRENCH
              : selectedTranslationLanguage === TranslationLanguage.FRENCH
              ? TranslationLanguage.GERMAN
              : selectedTranslationLanguage === TranslationLanguage.GERMAN
              ? TranslationLanguage.ITALIAN
              : selectedTranslationLanguage === TranslationLanguage.ITALIAN
              ? TranslationLanguage.POLISH
              : selectedTranslationLanguage === TranslationLanguage.POLISH
              ? TranslationLanguage.PORTUGESE
              : selectedTranslationLanguage === TranslationLanguage.PORTUGESE
              ? TranslationLanguage.RUSSIAN
              : selectedTranslationLanguage === TranslationLanguage.RUSSIAN
              ? TranslationLanguage.SPANISH
              : selectedTranslationLanguage === TranslationLanguage.SPANISH
              ? TranslationLanguage.CHINESE
              : TranslationLanguage.POLISH
          )
        }
      >
        <Translation text={"buttons.translation"} />
        {": "}
        <Translation text={languageKey} />
      </button>
    );
  };
  return (
    <>
      <div
        className="main-content-wrapper"
        style={{
          marginTop: 0,
        }}
      >
        <section className="main-section">
          <h3 className="a-text --header">
            <Translation text="menu.title" />
          </h3>
          <p className="a-text --medium">
            <Translation text="menu.subtitle" />
          </p>
          <VideoComponent />
          {renderLevelControls(levelControls)}
          <button
            className="btn btn-start a-text --header"
            style={{
              marginBottom: 0,
            }}
            onClick={() => navigate("content")}
          >
            <Translation text="buttons.start" />
          </button>
          {renderModeControl()}
          {renderLanguageTranslationControl()}
        </section>

        <p className="a-text --center">
          <Translation text="menu.description" />
        </p>
        <div className="app-links">
          <a href="#" target="_blank" rel="noreferrer">
            <Translation text="buttons.comingSoon" />
          </a>

          <a href="#" target="_blank" rel="noreferrer" className="app-store">
            <Translation text="buttons.comingSoon" />
          </a>
        </div>
        <a
          className="credentials custom-credentials a-text"
          href="https://thetime.pl/"
          target="_blank"
          rel="noreferrer"
        >
          <Translation text="homePage.credentials" />
        </a>
        {/* <AdSense.Google
                  client={siteKey}
                  slot={adSenseSlot}
                  style={{}}
                  layout='in-article'
                  format='fluid'
              /> */}
      </div>
    </>
  );
}
