import React, { Component } from "react";
import Translation from "../../../Translation";
import {
  changePronunciationLanguage,
  fetchWords,
  IWordItem,
  PronunciationLanguage,
} from "../../../../store/reducers/sagaSlice";
import {
  withRouterWrapper,
  WithRouterWrapperProps,
} from "../../../hoc/withRouterWrapper";

enum SummaryHintType {
  INITIAL = "initial",
  FIRST_LETTER = "first_letter",
  FULL_WORD = "full_word",
  UK_PRONUNCIATION = "uk_pronunciation",
  US_PRONUNCIATION = "us_pronunciation",
}

interface ISummarySlideProps extends WithRouterWrapperProps {
  readonly selectedWords: IWordItem[] | null;
  readonly pronunciationLanguage: PronunciationLanguage;
  readonly fetchWords: typeof fetchWords;
  readonly changePronunciationLanguage: typeof changePronunciationLanguage;
}

interface ISummarySlideState {
  summaryHintType: SummaryHintType;
  selectedWordIndex: number;
  selectedHintIndex: number;
  responsesShown: boolean;
  lastPlayedWordIndex: number;
}

const hintArray = [
  SummaryHintType.INITIAL,
  SummaryHintType.FIRST_LETTER,
  SummaryHintType.FULL_WORD,
  SummaryHintType.UK_PRONUNCIATION,
  SummaryHintType.US_PRONUNCIATION,
];

class SummarySlide extends Component<ISummarySlideProps, ISummarySlideState> {
  private readonly audioRef: any;

  constructor(props: ISummarySlideProps) {
    super(props);

    this.state = {
      summaryHintType: SummaryHintType.INITIAL,
      selectedWordIndex: 0,
      selectedHintIndex: 0,
      responsesShown: false,
      lastPlayedWordIndex: -1,
    };

    this.audioRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, true);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, true);
    if (this.audioRef && this.audioRef.current) {
      this.audioRef.current.pause();
    }
  }

  render() {
    const words = this.props.selectedWords,
      wordIndex = this.state.selectedWordIndex;

    return (
      <>
        <button
          className="btn btn-toggle-modal"
          onClick={() => this.props.router.navigate("/")}
        />

        <div className="slide-content">
          <div className="slide-content-background">
            <div className="slide">
              <p className="a-text --headerSpaced --uppercase">
                <Translation text="summary.title" />
              </p>

              {words ? (
                <ul className="summary-navigation">
                  {!this.state.responsesShown
                    ? words.map((word, index) => {
                        return (
                          <li
                            key={index}
                            className={`${
                              index === wordIndex ? "active" : ""
                            } a-text --header`}
                            onClick={() => {
                              this.selectSummaryItem(index);
                            }}
                          >
                            {index === wordIndex
                              ? this.renderSummaryDisplay(index, word)
                              : index + 1}
                          </li>
                        );
                      })
                    : words.map((word, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              this.setState({ selectedWordIndex: index }, () =>
                                this.playAudio()
                              );
                            }}
                            className={`${
                              index === wordIndex ? "active" : ""
                            } a-text --header`}
                          >
                            {word.english}
                          </li>
                        );
                      })}
                </ul>
              ) : null}

              <button
                className="btn btn-more-words a-text --medium"
                onClick={() => this.resetWords()}
              >
                <Translation
                  text={
                    this.state.responsesShown
                      ? "summary.moreWords"
                      : "summary.allWords"
                  }
                />
              </button>

              {/* <p className="app-details">
                <Translation text="summary.downloadApp" />
              </p> */}
            </div>
          </div>
        </div>
      </>
    );
  }

  private renderSummaryDisplay = (index: number, word: IWordItem): string => {
    let hint: string = (index + 1).toString(),
      hintType = this.state.summaryHintType;

    if (hintType === SummaryHintType.FIRST_LETTER) {
      hint = word.english.charAt(0);
    }

    if (hintType === SummaryHintType.FULL_WORD) {
      hint = word.english;
    }

    if (hintType === SummaryHintType.UK_PRONUNCIATION) {
      hint = word.br;
    }

    if (hintType === SummaryHintType.US_PRONUNCIATION) {
      hint = word.am;
    }

    return hint;
  };

  private handleKeyDown = (e: any) => {
    const index = this.state.selectedWordIndex,
      hintIndex = this.state.selectedHintIndex,
      words = this.props.selectedWords;
    let inputHintIndex = this.state.selectedHintIndex;

    if (words === null) {
      return;
    }

    if (e.keyCode === 40 && index < words.length - 1) {
      this.setState((prevState) => ({
        selectedWordIndex: prevState.selectedWordIndex + 1,
        selectedHintIndex: 0,
        summaryHintType: SummaryHintType.INITIAL,
      }));
    }

    if (e.keyCode === 38 && index > 0) {
      this.setState((prevState) => ({
        selectedWordIndex: prevState.selectedWordIndex - 1,
        selectedHintIndex: 0,
        summaryHintType: SummaryHintType.INITIAL,
      }));
    }

    if (e.keyCode === 39) {
      inputHintIndex = inputHintIndex + 1;
      if (inputHintIndex <= hintArray.length - 1) {
        this.setState((prevState) => ({
          summaryHintType: hintArray[prevState.selectedHintIndex + 1],
          selectedHintIndex: prevState.selectedHintIndex + 1,
        }));
      } else {
        this.setState({
          summaryHintType: SummaryHintType.INITIAL,
          selectedHintIndex: 0,
        });
      }
    }

    if (e.keyCode === 37 && hintIndex > 0) {
      this.setState((prevState) => ({
        summaryHintType: hintArray[prevState.selectedHintIndex - 1],
        selectedHintIndex: prevState.selectedHintIndex - 1,
      }));
    }

    if (e.keyCode === 32) {
      this.resetWords();
    }

    if (e.keyCode === 27) {
      this.props.router.navigate("/");
    }
  };

  private selectSummaryItem = (index: number) => {
    this.setState((prevState) => {
      const summyHintType = hintArray[prevState.selectedHintIndex + 1];
      if (
        summyHintType === SummaryHintType.UK_PRONUNCIATION ||
        summyHintType === SummaryHintType.US_PRONUNCIATION
      ) {
        this.playAudio(summyHintType);
      }
      return {
        selectedWordIndex: index,
        summaryHintType:
          index === prevState.selectedWordIndex
            ? hintArray[prevState.selectedHintIndex + 1]
            : SummaryHintType.FIRST_LETTER,
        selectedHintIndex:
          index === prevState.selectedWordIndex
            ? prevState.selectedHintIndex < hintArray.length - 1
              ? prevState.selectedHintIndex + 1
              : 0
            : 1,
      };
    });
  };

  private playAudio = (puncation?: SummaryHintType) => {
    const selectedWord = this.props.selectedWords
      ? this.props.selectedWords[this.state.selectedWordIndex]
      : null;

    if (!selectedWord) {
      return;
    }
    let language = this.props.pronunciationLanguage;

    if (this.state.lastPlayedWordIndex === this.state.selectedWordIndex) {
      language =
        this.props.pronunciationLanguage === PronunciationLanguage.UK_EN
          ? PronunciationLanguage.US_EN
          : PronunciationLanguage.UK_EN;
      this.props.changePronunciationLanguage(language);
    }

    const wordPronunciation = this.state.responsesShown
        ? language === PronunciationLanguage.UK_EN
          ? selectedWord.mp3Br
          : selectedWord.mp3Am
        : puncation === SummaryHintType.UK_PRONUNCIATION
        ? selectedWord.mp3Br
        : selectedWord.mp3Am,
      audio = `https://firebasestorage.googleapis.com/v0/b/wrdz-app.appspot.com/o/files%2F${wordPronunciation}?alt=media`;
    this.audioRef.current = new Audio(audio);
    console.log("puncation", puncation);
    console.log("wordPronunciation", wordPronunciation);

    this.audioRef.current.play();
    this.setState({
      lastPlayedWordIndex: this.state.selectedWordIndex,
    });
  };

  private resetWords = () => {
    if (!this.state.responsesShown) {
      this.setState({ responsesShown: true });
    } else {
      this.props.router.navigate("/demo/content");
    }
  };
}

export default withRouterWrapper(SummarySlide);
