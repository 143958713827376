import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  changeCookies,
  fetchApplicationSettings,
} from '../../store/reducers/sagaSlice';
import { RootState } from '../../store/reducers';
import { isCookieAcceptedSelector } from '../../store/selectors/sagaSelectors';
import ContentMenu from '../ContentMenu';
import CookieNotice from '../CookieNotice';
import NotFound from '../NotFound';
import { Route, Routes } from 'react-router-dom';
import Introduction from '../Introduction';
import SlideContent from '../ContentMenu/SlideContent';

interface IPageHostProps extends WithTranslation {
  readonly isCookieAccepted: boolean | null;
  readonly changeCookies: typeof changeCookies;
  readonly setCookies: (param: any) => void;
  readonly fetchApplicationSettings: typeof fetchApplicationSettings;
}

interface IPageHostState {}

class PageHost extends React.Component<IPageHostProps, IPageHostState> {
  // componentDidMount() {
  //   console.log('component did mount fetchApplicationSettings');
  //   this.props.fetchApplicationSettings();
  // }

  render() {
    return (
      <main className='view'>
        <Routes>
          <Route path='/' key='homepage' element={<Introduction />} />

          <Route path='/demo/*' key='demo' element={<ContentMenu />} />
          <Route path={'/:slug'} element={<SlideContent />} key='word' />
          <Route path='*' element={<NotFound />} />
        </Routes>

        {null === this.props.isCookieAccepted ? (
          <CookieNotice changeCookies={this.changeCookies} />
        ) : null}
      </main>
    );
  }

  private changeCookies = (isAccepted: boolean) => {
    if (isAccepted) {
      this.props.setCookies('Cookie test name');
    }
    this.props.changeCookies(isAccepted);
  };
}

export default connect(
  (state: RootState) => ({
    isCookieAccepted: isCookieAcceptedSelector(state),
  }),
  {
    changeCookies,
    fetchApplicationSettings,
  }
)(withTranslation()(PageHost));
