import React, { Component } from "react";
import {
  changePronunciationLanguage,
  IWordItem,
  PronunciationLanguage,
} from "../../../../../store/reducers/sagaSlice";
import Translation from "../../../../Translation";

interface ISlideControlsProps {
  readonly pronunciationLanguage: PronunciationLanguage;
  readonly selectedWords: IWordItem[] | null;
  readonly selectedWordIndex: number;
  readonly audioRef: any;
  readonly changePronunciationLanguage: typeof changePronunciationLanguage;
  readonly changeWord: (index: number, shouldDisplaySummary?: boolean) => void;
  readonly navigationBtnsRef?: any;
  readonly pronunciationVersionBtnRef?: any;
}

interface ISlideControlsState {}

class SlideControls extends Component<
  ISlideControlsProps,
  ISlideControlsState
> {
  render() {
    const words = this.props.selectedWords,
      index = this.props.selectedWordIndex,
      hasWords = words !== null && words !== undefined && words.length,
      pronunciationKey =
        this.props.pronunciationLanguage === PronunciationLanguage.UK_EN
          ? "slideContent.ukVersion"
          : "slideContent.usVersion";

    return (
      <>
        {hasWords ? (
          <>
            <div className="pronunciation-slide-controls">
              <ul
                className="slide-indicator"
                ref={this.props.navigationBtnsRef}
              >
                {words?.map((word, wordIndex: number) => {
                  return (
                    <li
                      key={word?.id}
                      className={`${index === wordIndex ? "active" : ""}`}
                      onClick={() => this.props.changeWord(wordIndex)}
                    />
                  );
                })}
              </ul>

              <button
                className="btn pronunciation-version"
                ref={this.props.pronunciationVersionBtnRef}
                onClick={() => this.playAudio(true)}
              >
                <Translation text={pronunciationKey} />
              </button>
            </div>
          </>
        ) : null}
      </>
    );
  }

  private playAudio = (shouldChangePronunciationLanguage?: boolean) => {
    // const selectedWord = this.props.selectedWords
    //     ? this.props.selectedWords[this.props.selectedWordIndex]
    //     : null,
    const language =
      this.props.pronunciationLanguage === PronunciationLanguage.UK_EN
        ? PronunciationLanguage.US_EN
        : PronunciationLanguage.UK_EN;

    if (shouldChangePronunciationLanguage) {
      this.props.changePronunciationLanguage(language);
    }

    // if (!selectedWord) {
    //   return;
    // }

    // const pronunciationLanguage = shouldChangePronunciationLanguage
    //     ? language
    //     : this.props.pronunciationLanguage,
    //   wordPronunciation =
    // pronunciationLanguage === PronunciationLanguage.UK_EN
    //   ? selectedWord.mp3Br
    //   : selectedWord.mp3Am,
    //   audio = `https://firebasestorage.googleapis.com/v0/b/wrdz-app.appspot.com/o/files%2F${wordPronunciation}?alt=media`;

    // if (this.props.audioRef.current) {
    //   this.props.audioRef.current.pause();
    //   this.props.audioRef.current = new Audio(audio);
    //   this.props.audioRef.current.play();
    // } else {
    //   this.props.audioRef.current = new Audio(audio);
    //   this.props.audioRef.current.play();
    // }
  };
}

export default SlideControls;
