import React, { Component } from "react";
import {
  ApplicationMode,
  changePronunciationLanguage,
  IWordItem,
  PronunciationLanguage,
  TranslationLanguage,
} from "../../../../store/reducers/sagaSlice";
import Translation from "../../../Translation";
import {
  withRouterWrapper,
  WithRouterWrapperProps,
} from "../../../hoc/withRouterWrapper";
import SlideControls from "./SlideControls";

interface IPronunciationSlideProps extends WithRouterWrapperProps {
  readonly pronunciationLanguage: PronunciationLanguage;
  readonly selectedWords: IWordItem[] | null;
  readonly selectedWordIndex: number;
  readonly mode: ApplicationMode;
  readonly localMode: ApplicationMode;
  readonly changePronunciationLanguage: typeof changePronunciationLanguage;
  readonly changeLocalApplicationMode: () => void;
  readonly changeSelectedWordIndex: (index: number) => void;
  readonly translationLanguage: TranslationLanguage;
}

interface IPronunciationSlideState {
  selectedWordIndex: number;
}

class PronunciationSlide extends Component<
  IPronunciationSlideProps,
  IPronunciationSlideState
> {
  private readonly audioRef: any;
  private readonly nextBtnRef: any;
  private readonly navigationBtnsRef: any;
  private readonly pronunciationVersionBtnRef: any;
  private readonly prevBtnRef: any;

  constructor(props: IPronunciationSlideProps) {
    super(props);

    this.state = {
      selectedWordIndex: this.props.selectedWordIndex
        ? this.props.selectedWordIndex
        : 0,
    };

    this.audioRef = React.createRef();
    this.nextBtnRef = React.createRef();
    this.navigationBtnsRef = React.createRef();
    this.pronunciationVersionBtnRef = React.createRef();
    this.prevBtnRef = React.createRef();
  }

  componentDidMount() {
    this.playAudio();
    document.addEventListener("keydown", this.handlePronunciationKeyDown, true);
    // document.addEventListener("click", this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.handlePronunciationKeyDown,
      true
    );
    // document.removeEventListener("click", this.handleClick, true);

    if (this.audioRef && this.audioRef.current) {
      this.audioRef.current.pause();
    }
  }

  render() {
    const words = this.props.selectedWords,
      index = this.state.selectedWordIndex,
      hasWords = words !== null && words !== undefined && words.length,
      isWordMode = this.props.mode === ApplicationMode.WORD,
      translationLanguage = this.props.translationLanguage;

    return (
      <>
        <button
          className="btn btn-toggle-modal hover-show"
          ref={this.prevBtnRef}
          onClick={() => this.props.router.navigate("/")}
        />
        <div className="slide">
          {hasWords ? (
            <>
              <SlideControls
                pronunciationLanguage={this.props.pronunciationLanguage}
                selectedWords={this.props.selectedWords}
                selectedWordIndex={this.props.selectedWordIndex}
                audioRef={this.audioRef}
                changeWord={this.changeWord}
                changePronunciationLanguage={
                  this.props.changePronunciationLanguage
                }
              />
              <div className="pronunciation-details">
                <p className="sub-heading">
                  {isWordMode
                    ? words[index]?.english
                    : this.props.pronunciationLanguage ===
                      PronunciationLanguage.UK_EN
                    ? words[index].br
                    : words[index]?.am}
                </p>
                <p
                  className="word pronunciation"
                  key={index}
                  onClick={() => this.playAudio()}
                >
                  {!isWordMode
                    ? words[index].english
                    : this.props.pronunciationLanguage ===
                      PronunciationLanguage.UK_EN
                    ? words[index].br
                    : words[index]?.am}
                </p>
                <button
                  className="btn btn-next a-text --header"
                  ref={this.nextBtnRef}
                  onClick={() => this.changeWord(index + 1, true)}
                >
                  <Translation text="buttons.next" />
                </button>
              </div>
              <div className="pronunciation-translations">
                {translationLanguage === TranslationLanguage.CHINESE
                  ? words[index]?.chinese
                  : translationLanguage === TranslationLanguage.ENGLISH
                  ? words[index]?.english
                  : translationLanguage === TranslationLanguage.FRENCH
                  ? words[index]?.french
                  : translationLanguage === TranslationLanguage.GERMAN
                  ? words[index]?.german
                  : translationLanguage === TranslationLanguage.ITALIAN
                  ? words[index]?.italian
                  : translationLanguage === TranslationLanguage.POLISH
                  ? words[index]?.polish
                  : translationLanguage === TranslationLanguage.PORTUGESE
                  ? words[index]?.portugese
                  : translationLanguage === TranslationLanguage.RUSSIAN
                  ? words[index]?.russian
                  : translationLanguage === TranslationLanguage.SPANISH
                  ? words[index]?.spanish
                  : words[index]?.polish}
              </div>
            </>
          ) : (
            <p className="no-results">
              <Translation text="slideContent.noResults" />
            </p>
          )}
        </div>
      </>
    );
  }

  private handlePronunciationKeyDown = (e: any) => {
    const index = this.state.selectedWordIndex,
      words = this.props.selectedWords;

    if (words === null) {
      return;
    }

    if (e.keyCode === 39 || e.keyCode === 32) {
      if (index < words.length - 1) {
        this.setState(
          (prevState) => ({
            selectedWordIndex: prevState.selectedWordIndex + 1,
          }),
          () => {
            this.changeLocalApplicationMode(this.state.selectedWordIndex);
          }
        );
      } else {
        this.props.router.navigate("/demo/summary");
      }
    } else if (e.keyCode === 37 && index > 0) {
      this.setState(
        (prevState) => ({
          selectedWordIndex: prevState.selectedWordIndex, // - 1
        }),
        () => {
          this.changeLocalApplicationMode(this.state.selectedWordIndex);
        }
      );
    } else if (e.keyCode === 37 && index === 0) {
      this.changeLocalApplicationMode(this.state.selectedWordIndex);
    }

    if (e.keyCode === 13 || e.keyCode === 40) {
      this.playAudio();
    }

    if (e.keyCode === 38) {
      this.playAudio(true);
    }

    if (e.keyCode === 27) {
      this.props.router.navigate("/");
    }
  };

  private changeWord = (index: number, shouldDisplaySummary?: boolean) => {
    const words = this.props.selectedWords;

    if (words && index <= words.length - 1) {
      this.setState({ selectedWordIndex: index });
      this.changeLocalApplicationMode(index);
    }

    if (words && index > words.length - 1 && shouldDisplaySummary) {
      this.props.router.navigate("/demo/summary");
    }
  };

  private playAudio = (shouldChangePronunciationLanguage?: boolean) => {
    const selectedWord = this.props.selectedWords
        ? this.props.selectedWords[this.state.selectedWordIndex]
        : null,
      language =
        this.props.pronunciationLanguage === PronunciationLanguage.UK_EN
          ? PronunciationLanguage.US_EN
          : PronunciationLanguage.UK_EN;
    if (shouldChangePronunciationLanguage) {
      this.props.changePronunciationLanguage(language);
    }

    if (!selectedWord) {
      return;
    }

    const pronunciationLanguage = shouldChangePronunciationLanguage
        ? language
        : this.props.pronunciationLanguage,
      wordPronunciation =
        pronunciationLanguage === PronunciationLanguage.UK_EN
          ? selectedWord.mp3Br
          : selectedWord.mp3Am,
      audio = `https://firebasestorage.googleapis.com/v0/b/wrdz-app.appspot.com/o/files%2F${wordPronunciation}?alt=media`;

    if (this.audioRef.current) {
      this.audioRef.current.pause();
      this.audioRef.current = new Audio(audio);
      this.audioRef.current.play();
    } else {
      this.audioRef.current = new Audio(audio);
      this.audioRef.current.play();
    }
  };

  private shareUrl = () => {
    const words = this.props.selectedWords,
      index = this.state.selectedWordIndex;
    if (null === words) {
      return;
    }

    const url = `${process.env.REACT_APP_URL}demo/content?uuid=${words[index].uid}`;
    navigator.clipboard.writeText(url);
  };

  // private handleClick = (e: any) => {
  //     if (this.nextBtnRef && this.nextBtnRef.current.contains(e.target) ||
  //         this.navigationBtnsRef && this.navigationBtnsRef.current.contains(e.target) ||
  //         this.prevBtnRef && this.prevBtnRef.current.contains(e.target) ||
  //         this.pronunciationVersionBtnRef && this.pronunciationVersionBtnRef.current.contains(e.target)
  //     ) {
  //         return;
  //     } else {
  //         this.props.changeLocalApplicationMode();
  //         this.props.changeSelectedWordIndex(this.state.selectedWordIndex);
  //     }
  // }

  private changeLocalApplicationMode = (index: number) => {
    this.props.changeLocalApplicationMode();
    this.props.changeSelectedWordIndex(index);
  };
}

export default withRouterWrapper(PronunciationSlide);
