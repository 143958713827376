import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import { persistReducer, persistStore } from "redux-persist";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants";
import storage from "redux-persist/lib/storage";
import { rootEpic } from "./epics";
import rootReducer from "./reducers";

const defaultMiddleware = getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  epicMiddleware = createEpicMiddleware();

defaultMiddleware.push(epicMiddleware);

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["saga"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: defaultMiddleware,
});
epicMiddleware.run(rootEpic);

const hotModule = module as typeof module & { hot: any };
if (process.env.NODE_ENV === "development" && hotModule.hot) {
  hotModule.hot.accept("./reducers", () => {
    const newRootReducer = require("./reducers").default;
    store.replaceReducer(newRootReducer);
  });
}

export const persistor = persistStore(store);
export default store;
