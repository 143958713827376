import React from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

interface IYouTubeVideoProps {
    videoId: string | null;
}

const YouTubeVideo: React.FC<IYouTubeVideoProps> = ({videoId}) => {
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // event.target.pauseVideo();
    }

    const opts: YouTubeProps['opts'] = {
        height: '169',
        width: '300',
        playerVars: {
            autoplay: 1,
        },
    };

    return (
        videoId !== null ? <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} /> : null
    );
}

export default YouTubeVideo;
