import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store/reducers";
import {
  applicationLevelSelector,
  applicationModeSelector,
  pronunciationLanguageSelector,
  selectedWordsSelector,
  isFilterActiveSelector,
  translationLanguageSelector,
} from "../../store/selectors/sagaSelectors";
import {
  ApplicationMode,
  changeApplicationLevel,
  changeApplicationMode,
  clearSelectedWords,
  toggleIsFilterWindowActive,
  fetchWords,
  IWordItem,
  PronunciationLanguage,
  changePronunciationLanguage,
  changeTranslationLanguage,
  TranslationLanguage,
} from "../../store/reducers/sagaSlice";
import { Route, Routes } from "react-router-dom";
import StartSlide from "./StartSlide";
import NotFound from "../NotFound";
import SlideContent from "./SlideContent";
import SummarySlide from "./SlideContent/SummarySlide";
import {
  withRouterWrapper,
  WithRouterWrapperProps,
} from "../hoc/withRouterWrapper";

interface IConnectedContentMenuProps {
  readonly level: number | null;
  readonly mode: ApplicationMode;
  readonly pronunciationLanguage: PronunciationLanguage;
  readonly isFilterWindowActive: boolean;
  readonly translationLanguage: TranslationLanguage;
  readonly selectedWords: IWordItem[] | null;
  readonly changeApplicationLevel: typeof changeApplicationLevel;
  readonly changeApplicationMode: typeof changeApplicationMode;
  readonly toggleIsFilterWindowActive: typeof toggleIsFilterWindowActive;
  readonly fetchWords: typeof fetchWords;
  readonly clearSelectedWords: typeof clearSelectedWords;
  readonly changePronunciationLanguage: typeof changePronunciationLanguage;
  readonly changeTranslationLanguage: typeof changeTranslationLanguage;
}

interface IExternalContentMenuProps {}

interface IContentMenuProps
  extends IConnectedContentMenuProps,
    IExternalContentMenuProps,
    WithRouterWrapperProps {}

class ContentMenu extends Component<IContentMenuProps> {
  componentWillUnmount() {
    this.props.clearSelectedWords();
  }

  render() {
    return (
      <div className="modal">
        <div className="modal-content">
          <Routes>
            <Route
              path="/"
              key="start-slide"
              element={
                <StartSlide
                  mode={this.props.mode}
                  level={this.props.level}
                  isFilterWindowActive={this.props.isFilterWindowActive}
                  changeApplicationLevel={this.props.changeApplicationLevel}
                  changeApplicationMode={this.props.changeApplicationMode}
                  changeTranslationLanguage={
                    this.props.changeTranslationLanguage
                  }
                  translationLanguage={this.props.translationLanguage}
                  toggleIsFilterWindowActive={
                    this.props.toggleIsFilterWindowActive
                  }
                />
              }
            />

            <Route path="content" key="content" element={<SlideContent />} />

            <Route
              path="summary"
              key="summary"
              element={
                <SummarySlide
                  fetchWords={this.props.fetchWords}
                  pronunciationLanguage={this.props.pronunciationLanguage}
                  selectedWords={this.props.selectedWords}
                  changePronunciationLanguage={
                    this.props.changePronunciationLanguage
                  }
                />
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    level: applicationLevelSelector(state),
    mode: applicationModeSelector(state),
    pronunciationLanguage: pronunciationLanguageSelector(state),
    selectedWords: selectedWordsSelector(state),
    isFilterWindowActive: isFilterActiveSelector(state),
    changePronunciationLanguage: changePronunciationLanguage,
    translationLanguage: translationLanguageSelector(state),
    changeTranslationLanguage: changeTranslationLanguage,
  }),
  {
    changeApplicationLevel,
    changeApplicationMode,
    fetchWords,
    clearSelectedWords,
    toggleIsFilterWindowActive,
    changePronunciationLanguage,
    changeTranslationLanguage,
  }
)(withRouterWrapper(ContentMenu));
