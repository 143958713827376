import { combineReducers } from "redux";
import sagaSlice from "./sagaSlice";


const rootReducer = combineReducers({
    saga: sagaSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
