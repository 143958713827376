import React, { useState } from 'react';
import './App.scss';
import PageHost from './components/PageHost';
import { useCookies } from 'react-cookie';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import Loader from './components/Loader/index';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [cookies, setCookie] = useCookies(['name']); // ToDo name of cookies to collect

  // ToDo check if cookies are set and remove check from reducer
  // useEffect(() => {
  //   console.log('cookies', cookies);
  // }, []);

  const auth = getAuth();
  signInAnonymously(auth)
    .then((res) => {
      setUserLoggedIn(true);
    })
    .catch((error) => {});

  function setCustomCookie(newName: any) {
    setCookie('name', newName, { path: '/' });
  }

  return userLoggedIn ? (
    <PageHost setCookies={setCustomCookie} />
  ) : (
    <Loader showLoader={true} />
  );
}

export default App;
