import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers";
import { ISagaState } from "../reducers/sagaSlice";

export const selectSaga = (state: RootState): ISagaState => {
  return state.saga;
};

export const activeLanguageSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.activeLanguage
);

export const applicationLevelSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.level
);

export const applicationSoundsFilterSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.soundsFilter
);

export const applicationModeSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.mode
);

export const translationLanguageSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.translationLanguage
);

export const previouslySelectedIdsSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.previouslySelectedIds
);

export const pronunciationLanguageSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.pronunciationLanguage
);

export const selectedWordsSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.selectedWords
);

export const isCookieAcceptedSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.isCookieAccepted
);

export const applicationSettingsSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.applicationSettings
);

export const applicationLevelsSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.applicationSettings?.levels
);

export const isLoadingSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.isLoading
);
export const isFilterActiveSelector = createSelector(
  [selectSaga],
  (state: ISagaState) => state.isFilterWindowActive
);
