import Translation from "../Translation";
import { isMobile } from "react-device-detect";
import VideoComponent from "../VideoComponent";
import { useNavigate } from "react-router-dom";

export default function Introduction() {
  const navigate = useNavigate();
  const redirectToAppDemo = () => {
    navigate("/demo");
  };

  const getUpperContent = () => {
    return (
      <div className="o-mainPage__relativeContent o-mainPage__upper">
        <h3 className="a-text --header --center">5000 words to&nbsp;learn</h3>
        <p className="a-text --medium --center">
          <Translation text="homePage.subtitle" />
        </p>
      </div>
    );
  };
  const getBottomContent = () => {
    return (
      <div className="o-mainPage__relativeContent o-mainPage__bottom">
        <p className="a-text --header --center">
          <Translation text="homePage.appTitle" />
        </p>
        <p className="a-text --center">
          <Translation text="homePage.description" />
        </p>

        {isMobile ? (
          <p className="a-text --center">
            <Translation text="homePage.mobileDescription" />
          </p>
        ) : null}
        <div className="o-mainPage__appLinks">
          <a
            href="/#"
            target="_blank"
            rel="noreferrer"
            className="disabled a-text --textItalic"
          >
            <Translation text="buttons.comingSoon" />
          </a>

          <a
            href="/#"
            target="_blank"
            rel="noreferrer"
            className="app-store disabled a-text --textItalic"
          >
            <Translation text="buttons.comingSoon" />
          </a>
        </div>
        <a
          className="o-mainPage__credentials a-text"
          href="https://thetime.pl/"
          target="_blank"
          rel="noreferrer"
        >
          <Translation text="homePage.credentials" />
        </a>
      </div>
    );
  };
  return (
    <div className="o-mainPage">
      <div className="o-mainPage__content">
        <div className="o-mainPage__centerCircleContainer">
          <div className="o-mainPage__centerCircleWrapper">
            <div
              className={`${
                isMobile ? "--smaller" : ""
              } o-mainPage__centerCircle`}
            ></div>
          </div>
        </div>
        <div
          className={`${
            isMobile ? "--smaller" : ""
          } o-mainPage__centerCirclePolyfill o-mainPage__relativeContent`}
        >
          {/* {isMobile ? (
            <VideoComponent />
          ) : ( */}
          <div
            className="o-mainPage__centerCircleContent"
            onClick={redirectToAppDemo}
          >
            <h1 className="o-mainPage__circleTitle">
              <Translation text="homePage.slogan" />
            </h1>
            <p className="o-mainPage__circleButton a-text --medium --uppercase btn ">
              <Translation text="homePage.demoDetails" />
            </p>
          </div>
          {/* )} */}
        </div>
        {getBottomContent()}
      </div>
    </div>
  );
}
