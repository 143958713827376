import React from 'react';

export enum LoaderType {
    GLOBAL = 'global',
    LOCAL = 'local'
}

interface ILoaderProps {
    showLoader: boolean;
    type?: LoaderType;
}

class Loader extends React.Component<ILoaderProps> {
    render() {
        const loaderType = this.props.type === LoaderType.LOCAL ? 'local' : '',
            isHidden = this.props.showLoader ? '' : 'is-hidden';

        return (
            <div className={`loader-container ${loaderType} ${isHidden}`}>
                <div className="loader" />
            </div>
        );
    }
}

export default Loader;
