import React, {Component} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';


export interface WithRouterWrapperProps  {
    router: {
        params: {[key: string]: any};
        navigate: (to: string, options?: {replace?: boolean; state?: any}) => void;
        location: {
            hash: string;
            key: string;
            pathname: string;
            search: string;
            state: {[key: string]: any} | null;
        };
    };
}


export const withRouterWrapper = (Component: any) => {
    function WithRouterComponent(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} router={{location, navigate, params}} />;
    }
    return WithRouterComponent;
};
