import React, {Component} from 'react';
import Translation from "../Translation";


interface ICookieNoticeProps {
    readonly changeCookies: (isAccepted: boolean) => void;
}

class CookieNotice extends Component<ICookieNoticeProps> {
    render() {
        return (
            <div className="cookie-box">
                <div className="cookie-content">
                    <p className="cookie-box-title"><Translation text="cookies.title" /></p>
                    <p className="cookie-box-content">
                        <Translation text="cookies.description" />
                        {/*<a href='#' target="_blank"><Translation text="cookies.link" /></a>*/}
                    </p>
                </div>
                <div className="cookie-actions">
                    <button onClick={() => this.props.changeCookies(false)}>
                        <Translation text="buttons.decline" />
                    </button>

                    <button className="btn-accept" onClick={() => this.props.changeCookies(true)}>
                        <Translation text="buttons.accept" />
                    </button>
                </div>
            </div>
        );
    }
}

export default CookieNotice;
